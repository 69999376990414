import actions from "../actions/actions";
import mutations from "../mutations/mutations";

function parseFeatureValue(value) {
  if (value) {
    if (value === "enabled") {
      return "enabled";
    }
    if (value === "true" || value === "published") {
      return "published";
    }
  }
  return "disabled";
}

export default {
  namespaced: true,
  state: {
    settings: [],
    associateDomains: {},
    isLoading: false,
  },
  mutations,
  actions,
  getters: {
    settingsLoaded: (state) => {
      return state.settings.length > 0;
    },
    styleVars: (state) => {
      const currentEntity =
        window.AppStore.state?.Entities?.currentEntity ?? null;
      const allStyles = state.settings.filter(
        (setting) => "styletoken" === setting.type
      );

      let styles = allStyles.filter((setting) => !setting.entity_id);

      if (currentEntity && currentEntity.id) {
        const entityId = parseInt(currentEntity.id, 10);
        const entityStyles = allStyles.filter(
          (setting) => parseInt(setting.entity_id, 10) === entityId
        );
        // Overwrite base styles with entity style values, if needed
        entityStyles.forEach((entityStyle) => {
          let baseStyle = styles.find((style) => entityStyle.key === style.key);
          baseStyle
            ? Object.assign(baseStyle, entityStyle)
            : styles.push(entityStyle);
        });
      }

      return styles && styles.length > 0 ? styles : [];
    },
    coreSettings: (state) => {
      return state.settings.filter((setting) => setting.type === "core");
    },
    footerSettings: (state) => {
      return state.settings.filter((setting) => setting.type === "footer");
    },
    beamFeatures: (state) => {
      let features = {
        authPages: "disabled",
        dxg: "disabled",
        entities: "disabled",
        fileExchange: "disabled",
        formEditor: "disabled",
        helpdesk: "disabled",
        internalAccessRequests: "disabled",
        localization: "disabled",
        navType: "default",
        pptGenerator: "disabled",
        public: "disabled",
        showcase: "disabled",
        notifications: "disabled",
        dataTables: "disabled",
      };

      const featureSet = state.settings.filter(
        (setting) => setting.type === "feature"
      );
      const coreSet = state.settings.filter(
        (setting) => setting.type === "core"
      );

      if (featureSet && featureSet.length > 0) {
        const authSettings = featureSet.find(
          (setting) => setting.key === "authPages"
        );
        features.authPages = !authSettings
          ? "disabled"
          : parseFeatureValue(authSettings.value);

        const dxgSettings = featureSet.find((setting) => setting.key === "dxg");
        features.dxg = !dxgSettings
          ? "disabled"
          : parseFeatureValue(dxgSettings.value);

        const entitiesSettings = featureSet.find(
          (setting) => setting.key === "entities"
        );
        features.entities = !entitiesSettings
          ? "disabled"
          : parseFeatureValue(entitiesSettings.value);

        const fileExchangeSettings = state.settings.find(
          (setting) => setting.key === "file-exchange"
        );
        features.fileExchange = !fileExchangeSettings
          ? "disabled"
          : parseFeatureValue(fileExchangeSettings.value);

        const formEditorSettings = featureSet.find(
          (setting) => setting.key === "form-editor"
        );
        features.formEditor = !formEditorSettings
          ? "disabled"
          : parseFeatureValue(formEditorSettings.value);

        const helpdeskSettings = featureSet.find(
          (setting) => setting.key === "helpdesk"
        );
        features.helpdesk = !helpdeskSettings
          ? "disabled"
          : parseFeatureValue(helpdeskSettings.value);

        const localizationSettings = featureSet.find(
          (setting) => setting.key === "localization"
        );
        features.localization = !localizationSettings
          ? "disabled"
          : parseFeatureValue(localizationSettings.value);

        const pptGenSettings = featureSet.find(
          (setting) => setting.key === "pptGenerator"
        );
        features.pptGenerator = !pptGenSettings
          ? "disabled"
          : parseFeatureValue(pptGenSettings.value);

        let publicSettings = featureSet.find(
          (setting) => setting.key === "public"
        );
        if (!publicSettings) {
          // If the new feature "public" is not found, check the original core version
          publicSettings = coreSet.find((setting) => setting.key === "public");
        }
        features.public = !publicSettings
          ? "disabled"
          : parseFeatureValue(publicSettings.value);

        const showcaseSettings = featureSet.find(
          (setting) => setting.key === "showcase"
        );
        features.showcase = !showcaseSettings
          ? "disabled"
          : parseFeatureValue(showcaseSettings.value);

        const notificationsSettings = featureSet.find(
          (setting) => setting.key === "notifications"
        );
        features.notifications = !notificationsSettings
          ? "disabled"
          : parseFeatureValue(notificationsSettings.value);

        const dataTablesSettings = featureSet.find(
          (setting) => setting.key === "data-tables"
        );
        features.dataTables = !dataTablesSettings
          ? "disabled"
          : parseFeatureValue(dataTablesSettings.value);

        const assetPermissionsSettings = featureSet.find(
          (setting) => setting.key === "asset-permissions"
        );
        features.assetPermissions = !assetPermissionsSettings
          ? "disabled"
          : parseFeatureValue(assetPermissionsSettings.value);

        const internalAccessRequestsSettings = featureSet.find(
          (setting) => setting.key === "internal-access-requests"
        );
        features.internalAccessRequests = !internalAccessRequestsSettings
          ? "disabled"
          : parseFeatureValue(internalAccessRequestsSettings.value);
      }

      if (coreSet && coreSet.length > 0) {
        const navType = coreSet.find((setting) => setting.key === "nav-type");
        if (navType) {
          features.navType = navType.value;
        }
      }

      return features;
    },
  },
};
