import { RouteUtils } from '@core/js'

import Account from '@core/router/routes/modules/account';
import AssetQueue from '@core/router/routes/modules/asset-queue'
import AssetViewer from '@core/router/routes/modules/asset-viewer'
import Assets from '@core/router/routes/modules/assets'
import Auth from '@core/router/routes/modules/auth'
import Content from '@core/router/routes/modules/content'
import Fallbacks from '@core/router/routes/modules/fallbacks'
import Home from '@core/router/routes/modules/home'
import Redirects from '@core/router/routes/modules/redirects'
import Search from '@core/router/routes/modules/search'
import XMPie from '@core/router/routes/modules/xmpie'

/*  reserved route names:
    'admin',
    'bookmarks',
    'collection',
    'content',
    'dxg',
    'helpdesk',
    'lightbox',
    'login',
    'logout',
    'profile',
    'showcase',
    'site', (Beam 2 Redirects)
    'panel' (Beam 2 Redirects)
*/

export default [
  {
    path: `/:locale${RouteUtils.localeRegex()}?/:entity${RouteUtils.entityRegex()}?`,
    name: 'params.optional',// Vue router complains about this name, but it's necessary for the Wysiwyg Link Generator
    component: {
      render(h) { return h('router-view') }
    },
    children: [
      Home,
      ...Account,
      Assets,
      AssetQueue,
      AssetViewer,
      ...Auth,
      ...Content,
      Search,
      XMPie,
      ...Redirects,
      ...Fallbacks
    ]
  },
  // ...Fallbacks,
  {
    path: '*',
    component: () => import(
      /* webpackChunkName: "main-notfound" */
      '@core/components/NotFound.vue')
  }
]
